<div class="layout-topbar">
  <a class="layout-topbar-logo flex gap-2">
    <div class="flex items-center gap-2">
      <img src="/assets/images/logo.png" alt="logo" />
      <span class="text-[13px] mt-auto">{{ version }}</span>
    </div>
  </a>

  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>

  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{ 'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible }"
  >
    <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Perfil</span>
        </button> -->
    <p-dropdown
      *ngIf="showSeasons"
      [options]="seasons"
      [ngModel]="season$ | async"
      (ngModelChange)="changeSeason($event)"
    ></p-dropdown>
    <button class="p-link layout-topbar-button" (click)="logout()">
      <i class="pi pi-sign-out"></i>
      <span>Cerrar Sesión</span>
    </button>
  </div>
</div>
