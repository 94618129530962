import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { DropdownModule } from 'primeng/dropdown';
import { SeasonManagerService } from '@services/season-manager.service';
import { FormsModule } from '@angular/forms';
import { environment } from 'apps/admin/src/environments/environment';

@Component({
  selector: 'app-topbar',
  standalone: true,
  templateUrl: './app.topbar.component.html',
  imports: [CommonModule, RouterModule, DropdownModule, FormsModule],
})
export class AppTopBarComponent {
  items!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  public season$ = this.seasonManager.season$;
  public seasons = this.seasonManager.availableSeasons();

  showSeasons = true;

  version = environment.version;

  constructor(
    public layoutService: LayoutService,
    public authService: AuthService,
    private router: Router,
    private seasonManager: SeasonManagerService,
  ) {
    // hay que implementar que si la ruta contiene /users o /clubs no se muestre el selector de temporadas
    this.router.events.subscribe((val) => {
      this.showSeasons =
        !this.router.url.includes('/users') &&
        !this.router.url.includes('/clubs') &&
        !this.router.url.includes('/license-rates') &&
        !this.router.url.includes('/emails') &&
        !this.router.url.includes('/notifications') &&
        !this.router.url.includes('/referees/coordinate');
    });
  }

  logout() {
    this.authService.logout().then(() => {
      this.router.navigate(['/login']);
    });
  }

  changeSeason(season: any) {
    this.seasonManager.setSeason(season);
  }
}
