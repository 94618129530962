import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatsService } from '@services/stats.service';
import { ChartModule } from 'primeng/chart';
import { tap } from 'rxjs';

@Component({
  selector: 'admin-admin-home',
  standalone: true,
  imports: [CommonModule, ChartModule],
  templateUrl: './admin-home.component.html',
  styles: ``,
})
export class AdminHomeComponent {
  licensesByType: any;
  movementsByType: any;

  options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            let label = tooltipItem.label || '';
            if (label) {
              label += ': ';
            }
            const value = tooltipItem.raw;
            label += new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
            return label;
          },
        },
      },
    },
  };

  adminStats$ = this.statsService.adminStats$.pipe(
    tap((stats) => {
      this.licensesByType = {
        labels: ['Jugadores', 'Delegados', 'Árbitros', 'Independientes'],
        datasets: [
          {
            data: [
              stats.incomesLicensesByType.PLAYER,
              stats.incomesLicensesByType.DELEGATE,
              stats.incomesLicensesByType.REFEREE,
              stats.incomesLicensesByType.INDEPENDENT,
            ],
            backgroundColor: ['#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
            hoverBackgroundColor: ['#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
          },
        ],
      };
      this.movementsByType = {
        labels: [...Object.keys(stats.incomesByType)],
        datasets: [
          {
            data: [...Object.values(stats.incomesByType)],
            backgroundColor: ['#FF6384', '#36A2EB', ...this.generateColors(stats.incomesByType)],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', ...this.generateColors(stats.incomesByType)],
          },
        ],
      };
    }),
  );

  generateColors(type: Record<string, number>): string[] {
    const colors = ['#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
    const result: string[] = [];
    Object.keys(type).forEach((_, index) => {
      result.push(colors[index % colors.length]);
    });
    return result;
  }

  constructor(private statsService: StatsService) {}
}
