import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user.service';
import { AppMenuitemComponent } from './app.menuitem.component';
import { MenuItem } from 'primeng/api';
import { Observable, combineLatest, firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-menu',
  standalone: true,
  templateUrl: './app.menu.component.html',
  imports: [CommonModule, AppMenuitemComponent],
})
export class AppMenuComponent {
  isAdmin$ = this.usersService.isAdmin$;
  isManager$ = this.usersService.isManager$;
  isRefereeCoordinator$ = this.usersService.isRefereeCoordinator$;

  menuItems$: Observable<MenuItem[]> = combineLatest([this.isAdmin$, this.isManager$, this.isRefereeCoordinator$]).pipe(
    map(([isAdmin, isManager, isRefereeCoordinator]) => [
      {
        label: 'Home',
        items: [{ label: 'Inicio', icon: 'pi pi-fw pi-home', routerLink: [''] }],
      },
      {
        label: 'Reportes',
        items: [
          { label: 'Principal', icon: 'pi pi-fw pi-chart-bar', routerLink: ['reports'] },
          { label: 'Económico', icon: 'pi pi-fw pi-chart-line', routerLink: ['reports/economy'] },
          { label: 'Deportivo', icon: 'pi pi-fw pi-chart-pie', routerLink: ['reports/sports'] },
          { label: 'Estadístico', icon: 'pi pi-fw pi-chart-scatter', routerLink: ['reports/stats'] },
        ],
        visible: isAdmin,
      },
      {
        label: 'Gestor de Club',
        items: [
          { label: 'Mis Equipos', icon: 'pi pi-fw pi-users', routerLink: ['club/teams'] },
          { label: 'Mis Jugadores', icon: 'pi pi-fw pi-user', routerLink: ['club/players'] },
        ],
        visible: isManager,
      },
      {
        label: 'Árbitros',
        items: [
          { label: 'Asignar Árbitros', icon: 'pi pi-fw pi-user', routerLink: ['referees/coordinate'] },
        ],
        visible: isRefereeCoordinator,
      },
      {
        label: 'Administración',
        items: [
          { label: 'Usuarios', icon: 'pi pi-fw pi-user', routerLink: ['users'] },
          { label: 'Clubs', icon: 'pi pi-fw pi-building', routerLink: ['clubs'] },
          { label: 'Equipos', icon: 'pi pi-fw pi-users', routerLink: ['teams'] },
          { label: 'Competiciones', icon: 'pi pi-fw pi-sitemap', routerLink: ['competitions'] },
        ],
        visible: isAdmin,
      },
      {
        label: 'Avisos',
        items: [
          { label: 'Emails', icon: 'pi pi-fw pi-envelope', routerLink: ['emails'] },
          { label: 'Notificaciones', icon: 'pi pi-fw pi-bell', routerLink: ['notifications'] },
        ],
        visible: isAdmin,
      },
      {
        label: 'Gestiones Temporada',
        items: [
          { label: 'Calendario', icon: 'pi pi-fw pi-calendar', routerLink: ['calendar'] },
          { label: 'Cambios Fechas', icon: 'pi pi-fw pi-calendar-clock', routerLink: ['change-dates'] },
          { label: 'Incidencias', icon: 'pi pi-fw pi-volume-up', routerLink: ['incidents'] },
          { label: 'Traspasos', icon: 'pi pi-fw pi-arrow-right-arrow-left', routerLink: ['transfers'] },
        ],
        visible: isAdmin,
      },
      {
        label: 'Económico',
        items: [
          { label: 'Ingresos', icon: 'pi pi-fw pi-euro', routerLink: ['movements'] },
          { label: 'Tarifas', icon: 'pi pi-fw pi-star', routerLink: ['license-rates'] },
          { label: 'Recibos', icon: 'pi pi-fw pi-send', routerLink: ['receipts'] },
          { label: 'Facturas Emitidas', icon: 'pi pi-fw pi-receipt', routerLink: ['invoices'] },
          { label: 'Facturas a Club TEMPORAL', icon: 'pi pi-fw pi-receipt', routerLink: ['club/invoices'] },
        ],
        visible: isAdmin,
      },
    ]),
  );
  constructor(public usersService: UserService) {}
}
