<main class="flex flex-col gap-4">
  <div class="flex justify-between items-center">
    <h4 class="ml-10 md:ml-0 !text-lg">
      Bienvenido {{ (user$ | async)?.name }} {{ (user$ | async)?.firstLastName }}
      {{ (user$ | async)?.secondLastName }}
      <ng-container *ngIf="myClub$ | async as club"> - {{ club.name }} </ng-container>
    </h4>
    <p-button
      *ngIf="(isAdmin$ | async) && (isClubManager$ | async)"
      (onClick)="isAdminDashboardSelected = !isAdminDashboardSelected"
      [rounded]="true"
      >Cambiar a Panel {{ isAdminDashboardSelected ? 'Club' : 'Admin' }}</p-button
    >
  </div>
  <div *ngIf="((isAdmin$ | async) && isAdminDashboardSelected) || (isAdmin$ | async) && !(isClubManager$ | async)" class="flex flex-col gap-8">
    <admin-admin-home></admin-admin-home>
  </div>
  <div *ngIf="(myClub$ | async) && (isClubManager$ | async) && !isAdminDashboardSelected" class="flex flex-col gap-8">
    @if (clubBalance$ | async; as clubBalance) {
      <section class="grid grid-cols-1 gap-8 xl:grid-cols-3 2xl:grid-cols-5 md:grid-cols-2">
        <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200">
          <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="35" cy="35" r="35" fill="#713BDB" fill-opacity="0.05" />
              <path
                d="M26 44C26 40.625 30.5 40.625 32.75 38.375C33.875 37.25 30.5 37.25 30.5 31.625C30.5 27.8754 31.9996 26 35 26C38.0004 26 39.5 27.8754 39.5 31.625C39.5 37.25 36.125 37.25 37.25 38.375C39.5 40.625 44 40.625 44 44"
                stroke="#6F52ED"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>

            <div class="mx-2">
              <h3 class="text-2xl font-medium text-gray-800">{{ myTeams$ | async }}</h3>
              <p class="mt-1 text-sm text-gray-500">Equipos</p>
            </div>
          </div>
        </div>
        <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200">
          <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="35" cy="35" r="35" fill="#713BDB" fill-opacity="0.05" />
              <path
                d="M26 44C26 40.625 30.5 40.625 32.75 38.375C33.875 37.25 30.5 37.25 30.5 31.625C30.5 27.8754 31.9996 26 35 26C38.0004 26 39.5 27.8754 39.5 31.625C39.5 37.25 36.125 37.25 37.25 38.375C39.5 40.625 44 40.625 44 44"
                stroke="#6F52ED"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>

            <div class="mx-2">
              <h3 class="text-2xl font-medium text-gray-800">{{ myPlayers$ | async }}</h3>
              <p class="mt-1 text-sm text-gray-500">Jugadores</p>
            </div>
          </div>
        </div>
        <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200">
          <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="35" cy="35" r="35" fill="#33D69F" fill-opacity="0.07" />
              <path d="M26 39L31 34" stroke="#21B8C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M32 34C32.5523 34 33 33.5523 33 33C33 32.4477 32.5523 32 32 32C31.4477 32 31 32.4477 31 33C31 33.5523 31.4477 34 32 34Z"
                stroke="#21B8C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M37 39C37.5523 39 38 38.5523 38 38C38 37.4477 37.5523 37 37 37C36.4477 37 36 37.4477 36 38C36 38.5523 36.4477 39 37 39Z"
                stroke="#21B8C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M38 37L44 31M33 34L36 37L33 34Z"
                stroke="#21B8C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="mx-2">
              <h3 class="text-2xl font-medium text-gray-800">
                {{ clubBalance?.income | currency: 'EUR' }}
              </h3>
              <p class="mt-1 text-sm text-gray-500">Ingresos</p>
            </div>
          </div>
        </div>
        <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200">
          <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="35" cy="35" r="35" fill="#FF4C61" fill-opacity="0.05" />
              <path d="M26 32L32 38L36 34L43.405 41.405" stroke="#FF4C61" stroke-width="2" stroke-linecap="square" />
              <path d="M43.405 41.405L44 42" stroke="#FF4C61" stroke-width="2" stroke-linecap="round" />
              <path d="M44 39V42H41" stroke="#FF4C61" stroke-width="2" stroke-linecap="square" />
            </svg>

            <div class="mx-2">
              <h3 class="text-2xl font-medium text-gray-800">
                {{ clubBalance?.expense | currency: 'EUR' }}
              </h3>
              <p class="mt-1 text-sm text-gray-500">Gastos</p>
            </div>
          </div>
        </div>
        <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200">
          <div class="flex items-center -mx-2">
            <svg class="mx-2" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="35" cy="35" r="35" fill="#33D69F" fill-opacity="0.07" />
              <path d="M26 39L31 34" stroke="#21B8C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M32 34C32.5523 34 33 33.5523 33 33C33 32.4477 32.5523 32 32 32C31.4477 32 31 32.4477 31 33C31 33.5523 31.4477 34 32 34Z"
                stroke="#21B8C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M37 39C37.5523 39 38 38.5523 38 38C38 37.4477 37.5523 37 37 37C36.4477 37 36 37.4477 36 38C36 38.5523 36.4477 39 37 39Z"
                stroke="#21B8C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M38 37L44 31M33 34L36 37L33 34Z"
                stroke="#21B8C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="mx-2">
              <h3 class="text-2xl font-medium text-gray-800">
                {{ clubBalance.balance | currency: 'EUR' }}
              </h3>
              <p class="mt-1 text-sm text-gray-500">Balance</p>
            </div>
          </div>
        </div>
        <div class="flex items-center px-6 py-8 bg-white rounded-lg shadow-md shadow-gray-200">
          <div class="flex items-center -mx-2">
            <div class="mx-2">
              <h3 class="text-2xl font-medium text-gray-800">IBAN Federación</h3>
              <p class="mt-1 text-sm text-gray-500">ES70 2100 2736 4102 0011 9530</p>
            </div>
            <i
              (click)="copy()"
              class="pi pi-copy"
              style="font-size: 2rem"
              pTooltip="Copiar Número de cuenta de la federación: ES70 2100 2736 4102 0011 9530"
              tooltipPosition="top"
            ></i>
          </div>
        </div>
      </section>
      <h4>Movimientos</h4>
      <p-table
        #dt2
        [value]="clubBalance?.movements ?? []"
        dataKey="id"
        [rows]="25"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[25, 50, 100]"
        [paginator]="true"
        currentPageReportTemplate="{first} a {last} de {totalRecords} registros"
        [tableStyle]="{ 'min-width': '75rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Fecha</th>
            <th>Forma de Pago</th>
            <th>Concepto</th>
            <th>Tipo</th>
            <th>Importe</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-movement>
          <tr>
            <td>{{ movement.createdAt | date: 'dd/MM/yyyy HH:mm' }}</td>
            <td>{{ movement.paymentMethod | paymentMethodLabel }}</td>
            <td>
              @if (movement.concept) {
                <div class="font-medium">{{ movement.concept }}</div>
              } @else {
                @if(movement.paymentType !== 'REFEREE'){
                  <div class="font-medium">Inscripción {{ movement.paymentType | paymentTypeLabel }}</div>
                } @else {
                  <div class="font-medium">{{ movement.paymentType | paymentTypeLabel }}</div>
                }
              }
            </td>
            <td>{{ movement.movementType | movementTypeLabel }}</td>
            <td>{{ movement.movementType === 'INCOME' ? '+' : '-' }} {{ movement.amount | currency: 'EUR' }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">No se han encontrado movimientos</td>
          </tr>
        </ng-template>
      </p-table>
    }
  </div>
</main>
